import * as React from "react";
import "@fontsource/dm-sans";
import "./pdc.sass";
import Layout from "../components/Layout";
import { Link } from "gatsby";

// markup
const Pdc = () => {
  return (
    <Layout>
      <div id="pdc-holder">
        <h2>POLITIQUE DE CONFIDENTIALITÉ</h2>
        <p id="first">
          Notre politique de confidentialité est rédigée dans le but de vous
          informer sur les données personnelles qui sont collectées par notre
          plateforme, le site legalify.ch. Ce document vous explique quels types
          de données sont collectées, de quelle manière ces données sont
          traitées et utilisées, ainsi que le but de leur utilisation. Les
          informations contenues ci-dessous sont susceptibles d’être modifiées
          de temps à autre, afin notamment d’améliorer nos services. Nous vous
          recommandons donc de consulter notre politique de confidentialité
          lorsque vous utilisez notre plateforme.
        </p>
        <h5>1. Données personnelles</h5>
        <p>
          Les données personnelles sont toutes les informations qui se
          rapportent à une personne physique ou morale, identifiée ou
          identifiable. Elles comprennent, par exemple, les nom(s) et prénom(s)
          d’une personne, sa raison sociale, son adresse postale ou son adresse
          e-mail, ses coordonnées bancaires ou toute autre information de nature
          personnelle.
        </p>
        <h5>2. Données collectées</h5>
        <p>
          <span>2.1</span> Nous collections les données personnelles fournies
          directement lorsque vous utilisez notre plateforme, par exemple
          lorsque vous commandez des prestations ou prenez contact avec nous.
          Les données collectées dans ces cas sont notamment vos nom(s) et
          prénom(s) ou votre raison sociale, votre adresse e-mail, votre adresse
          postale ou votre numéro de téléphone.
        </p>
        <p>
          <span>2.2</span> Nous collectons également certaines données
          personnelles de manière automatique, lorsque vous naviguez sur notre
          plateforme. Il s’agit notamment des données relatives à votre
          comportement sur notre plateforme, par exemple les pages consultées,
          le temps et la fréquence de consultation, les préférences de
          navigation, votre adresse IP, le nom de votre fournisseur d’accès à
          Internet, le pays à partir duquel vous consultez notre plateforme ou
          l’adresse du site à partir duquel vous avez été redirigé vers notre
          plateforme. Ces données sont collectées par l’intermédiaire de
          cookies. Vous trouverez les informations relatives à l’utilisation de
          cookies au point n° 4 ci-dessous.
        </p>
        <p>
          <span>2.3</span> Nous ne collectons pas les données relatives aux
          paiements effectués via notre plateforme. Celles-ci sont stockées chez
          notre partenaire en matière de paiement, la société Datatrans SA.
        </p>
        <h5>3. Utilisation des données collectées</h5>
        <p>
          <span>3.1</span> Nous utilisons en premier lieu les données
          personnelles collectées pour fournir les prestations que vous nous
          avez demandées. Vos nom(s) et prénom(s) ou votre raison sociale sont
          utilisés afin de contrôler l’existence d’éventuels conflits entre vos
          intérêts et nos activités. Votre numéro de téléphone est utilisé pour
          communiquer avec vous, notamment pour vous contacter lorsque vous avez
          réservé un entretien téléphonique. Votre adresse e-mail et votre
          adresse postale sont utilisées pour correspondre avec vous ou pour
          vous transmettre des documents.
        </p>
        <p>
          <span>3.2</span> Les données personnelles collectées automatiquement
          par la plateforme sont utilisées à l’interne, en vue d’améliorer nos
          prestations et de mieux vous servir. Elles peuvent, le cas échéant,
          être transmises à certains prestataires de services nous assistant
          dans le développement de la plateforme.
        </p>
        <h5>4. Utilisation des cookies</h5>
        <p>
          <span>4.1</span> Notre plateforme utilise des cookies. Ces fichiers
          nous permettent de connaître certaines informations concernant son
          utilisation, comme par exemple les pages consultées, les préférences
          de navigation des utilisateurs, leurs adresses IP ou encore les pays à
          partir desquels elle est consultée. Ils n’impactent pas la mémoire de
          votre appareil et ne nous transmettent pas vos données personnelles.
        </p>
        <p>
          <span>4.2</span> Les cookies nous donnent des informations générales,
          à partir desquelles nous pouvons obtenir des statistiques sur
          l’utilisation de notre plateforme. Il ne nous est toutefois pas
          possible d’établir une relation entre des données précises et un
          utilisateur en particulier. Vos données ne sont donc pas enregistrées
          dans ce cadre et les données récoltées par le biais de cookies sont
          anonymes.
        </p>
        <p>
          <span>4.3</span> Il vous est possible en tout temps de refuser à
          l’utilisation de cookies par notre plateforme. Pour cela, il vous
          suffit de les désactiver sur votre navigateur ou en suivant les
          instructions proposées lors de votre première connexion sur notre
          plateforme.
        </p>
        <h5>5. Outils de suivi</h5>
        <p>
          <span>5.1</span> Nous utilisons le système Google Analytics sur notre
          plateforme. Google Analytics est un outil de suivi permettant
          d’analyser certaines données relatives à l’utilisation et la
          fréquentation de notre plateforme. Il nous permet, à l’aide de
          cookies, de collecter des informations comme notamment les pages
          consultées ou les adresses IP des utilisateurs et leurs préférences de
          navigation, et d’en tirer des informations statistiques sur le
          fonctionnement de notre plateforme, au moyen de rapports d’activités.
        </p>
        <p>
          <span>5.2</span> Les données collectées à l’aide de Google Analytics
          sont anonymes et ne nous permettent pas de vous identifier
          personnellement. L’utilisation de cet outil vise uniquement à
          connaître le détail de la fréquentation de notre plateforme, afin d’en
          améliorer la conception et le fonctionnement, le cas échéant.
        </p>
        <p>
          <span>5.3</span> Google Analytics est un système fourni par la société
          Google Inc. aux États-Unis. Les données collectées par le système
          peuvent donc être envoyées et stockées sur les serveurs de Google aux
          États-Unis ou en Europe.
        </p>
        <p>
          <span>5.4</span> Il vous est possible de désactiver l’utilisation de
          Google Analytics sur notre plateforme en installant un plugin de
          navigateur sur votre appareil.
        </p>
        <h5>6. Google Maps</h5>
        <p>
          <span>6.1</span> Nous utilisons l’application Google Maps sur notre
          plateforme. Cette application nous permet de vous faciliter l’accès à
          nos locaux, en cas de rendez-vous personnel. Lors de l’utilisation de
          Google Maps, certaines de vos données sont collectées, notamment votre
          adresse IP, ainsi que les détails de votre utilisation de notre
          plateforme.
        </p>
        <p>
          <span>6.2</span> Les données collectées par Google Maps sont anonymes
          et ne nous permettent pas de vous identifier personnellement.
        </p>
        <p>
          <span>6.3</span> Google Maps est un système fourni par la société
          Google Inc. aux États-Unis. Les données collectées par le système
          peuvent donc être envoyées et stockées sur les serveurs de Google aux
          États-Unis ou en Europe.
        </p>
        <p>
          <span>6.4</span> Il vous est possible de désactiver l’utilisation de
          Google Maps sur votre appareil, ce qui empêchera le transfert de
          données à Google. L’affichage de la carte sur notre plateforme sera
          par contre également désactivé dans ce cas.
        </p>
        <h5>7. Adresses IP</h5>
        <p>
          L’adresse IP (Internet Protocol) de votre appareil nous permet de
          connaître certains détails liés à votre connexion, en particulier,
          l’endroit depuis lequel vous vous connectez (pays, région, ville).
        </p>
        <h5>8. Confidentialité des données collectées</h5>
        <p>
          <span>8.1</span> Les données collectées sont confidentielles et nous
          les conservons de manière sécurisée sur un serveur se trouvant dans
          nos locaux. Nos employés, représentants et auxiliaires peuvent y avoir
          accès
        </p>
        <p>
          <span>8.2</span> Nous pouvons transmettre vos données personnelles à
          des tiers dans les cas prévus par nos{" "}
          <Link to="/cg">conditions générales d’utilisation</Link> qui sont
          librement accessibles sur notre plateforme ou par cette politique de
          confidentialité. Il s’agit notamment des cas où la transmission de
          données est nécessaire pour fournir les prestations que vous nous avez
          demandées, pour nous conformer à des obligations légales ou pour
          protéger nos droits.
        </p>
        <h5>9. Stockage des données</h5>
        <p>
          Nous protégeons vos données personnelles par des mesures de sécurité
          techniques, ainsi que des mesures organisationnelles appropriées,
          aussi bien à l’interne, qu’en relation avec nos employés, nos
          représentants ou les prestataires de services externes auxquels nous
          faisons appel de temps à autre, afin de prévenir leur perte, leur
          transmission non-autorisée ou leur altération. Toute transmission de
          données par le biais d’Internet implique toutefois un risque que ces
          données soient accessibles à des tiers et que ceux-ci les utilisent à
          leur propre fin.
        </p>
        <h5>10. Durée de conservation des données</h5>
        <p>
          <span>10.1</span> Nous conservons vos données personnelles le temps de
          vous fournir tous les services que vous nous avez demandés.
        </p>
        <p>
          <span>10.2</span> Une fois les services rendus, nous conservons les
          données pendant une durée de dix ans, afin de remplir nos obligations
          légales. Ces données sont conservées de manière sécurisée et sont
          accessibles uniquement en vue de remplir ces obligations.
        </p>
        <h5>11. Renseignement, effacement et rectification des données</h5>
        <p>
          <span>11.1</span> En tant qu’utilisateur de notre plateforme, vous
          avez plusieurs droits en relation avec vos données personnelles. Il
          s’agit notamment du droit d’être renseigné à leur sujet, à tout moment
          et sans frais, du droit de les faire effacer ou corriger lorsqu’elles
          sont inexactes ou incomplètes, du droit de s’opposer à leur traitement
          ou d’en demander la limitation et du droit de déposer une réclamation
          à l’autorité compétente en matière de protection des données
          personnelles.
        </p>
        <p>
          <span>11.2</span> Vous pouvez nous contacter par écrit aux coordonnées
          mentionnées ci-dessous, afin de faire valoir vos droits, notamment de
          renseignement, d’effacement ou de rectification.
        </p>
        <p>
          <span>11.3</span> Les requêtes irréalisables, disproportionnées ou
          contraires à nos obligations de conservations des documents à des fins
          légales pourront être rejetées, selon notre propre appréciation.
        </p>
        <h5>12. Acceptation des conditions générales</h5>
        <p>
          <span>12.1</span> Cette politique de confidentialité fait partie
          intégrante de nos{" "}
          <Link to="/cg">conditions générales d’utilisation</Link> qui
          s’appliquent à l’utilisation de notre plateforme et qui y sont
          librement accessibles.
        </p>
        <p>
          <span>12.2</span> Lorsque vous nous transmettez des données
          personnelles, vous acceptez que celles-ci soient collectées, traitées
          et conservées selon les termes de nos{" "}
          <Link to="/cg">conditions générales d’utilisation</Link> et de cette
          politique de confidentialité.
        </p>
        <p>
          <span>12.3</span> Vous pouvez à tout moment choisir de ne pas nous
          transmettre vos données personnelles ou retirer votre consentement à
          leur utilisation. Toutefois, dans certains cas, vos données
          personnelles nous sont nécessaires pour fournir des services. Il est
          donc possible, dans de pareils cas, que nous ne puissions pas vous
          fournir tous les services que vous demandez.
        </p>
        <h5>13. Contact</h5>
        <p>
          <span>13.1</span> En tant qu’opératrice du site legalify.ch, la
          société Legalify Sàrl (Rue des Alpes 15, c/o Pierre Vuille et
          Christophe Zermatten, avocats, 1201 Genève, Suisse) est responsable du
          respect des dispositions légales applicables en matière de traitement
          et de protection des données à caractère personnel.
        </p>
        <p>
          <span>13.2</span> Pour toute remarque ou question en relation avec
          notre politique de confidentialité, vous pouvez nous contacter par
          e-mail à l’adresse{" "}
          <a href="mailto:info@legalify.ch">info@legalify.ch</a> ou par courrier
          à l’adresse suivante :
        </p>
        <p>
          <span className="indent">
            Legalify Sàrl <br />
            Rue des Alpes 15 <br />
            c/o Pierre Vuille et Christophe Zermatten
            <br />
            Case postale 1592 <br />
            1211 Genève 1
          </span>
        </p>
      </div>
    </Layout>
  );
};

export default Pdc;
